import { template as template_ac7fea6ab1ad4c809e7d2dd29ac8dda8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ac7fea6ab1ad4c809e7d2dd29ac8dda8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
