import { template as template_540caee51abb4da489a3f4f47a4a8aef } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_540caee51abb4da489a3f4f47a4a8aef(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
