import { template as template_976a38112107404892d95cf0f2b8b686 } from "@ember/template-compiler";
const WelcomeHeader = template_976a38112107404892d95cf0f2b8b686(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
